import appendSlide from './mDEMO2ods/appendSlide.js';
import prependSlide from './mDEMO2ods/prependSlide.js';
import addSlide from './mDEMO2ods/addSlide.js';
import removeSlide from './mDEMO2ods/removeSlide.js';
import removeAllSlides from './mDEMO2ods/removeAllSlides.js';

export default function Manipulation({ swiper }) {
  Object.assign(swiper, {
    appendSlide: appendSlide.bind(swiper),
    prependSlide: prependSlide.bind(swiper),
    addSlide: addSlide.bind(swiper),
    removeSlide: removeSlide.bind(swiper),
    removeAllSlides: removeAllSlides.bind(swiper),
  });
}
